import Vue from 'vue';

import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { email } from 'vee-validate/dist/rules';
// Add a rule.
extend('required', {
  validate(value) {
    return {
      required: true,
      valid: !['', null, undefined].includes(value),
    };
  },
  computesRequired: true,
  message: (field) => `Chưa nhập "${field}"`,
});

extend('requiredWithoutName', {
  validate(value) {
    return {
      required: true,
      valid: !['', null, undefined].includes(value),
    };
  },
  computesRequired: true,
  message: () => 'Chưa nhập trường này',
});

extend('email', {
  ...email,
  message: (field) => `Định dạng "${field}" chưa đúng`,
});

extend('positive', {
  validate: (value) => value > 0,
  message: (field) => `"${field}" phải > 0`,
});

extend('excel', {
  validate: (file) => {
    const { name } = file;
    const ext = name.substr(name.lastIndexOf('.') + 1, name.length);
    return !!(ext === 'xls' || ext === 'xlsx');
  },
  message: () => 'Định dạng "File" phải là "xlsx, xls"',
});

Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);
