import mock from '@/@fake-db/mock';
import { paginateArray, sortCompare } from '@/@fake-db/utils';

/* eslint-disable global-require */
const data = {
  contracts: [
    {
      id: 1,
      customer: 'Nguyễn Văn A',
      partner: 'viec.co',
      contractId: '112',
      amount: '10.000.000',
      limitation: '10.000.000',
      date: '12/05/2021',
      username: 'gslixby0',
      status: 'active',
      avatar: '',
    },
    {
      id: 2,
      customer: 'Nguyễn Văn A',
      partner: 'viec.co',
      contractId: '112',
      amount: '10.000.000',
      limitation: '10.000.000',
      date: '12/05/2021',
      username: 'gslixby0',
      status: 'active',
      avatar: '',
    },
    {
      id: 3,
      customer: 'Nguyễn Văn A',
      partner: 'viec.co',
      contractId: '112',
      amount: '10.000.000',
      limitation: '10.000.000',
      date: '12/05/2021',
      username: 'gslixby0',
      status: 'active',
      avatar: '',
    },
    {
      id: 4,
      customer: 'Nguyễn Văn A',
      partner: 'viec.co',
      contractId: '112',
      amount: '10.000.000',
      limitation: '10.000.000',
      date: '12/05/2021',
      username: 'gslixby0',
      status: 'active',
      avatar: '',
    },
    {
      id: 5,
      customer: 'Nguyễn Văn A',
      partner: 'viec.co',
      contractId: '112',
      amount: '10.000.000',
      limitation: '10.000.000',
      date: '12/05/2021',
      username: 'gslixby0',
      status: 'active',
      avatar: '',
    },
    {
      id: 6,
      customer: 'Nguyễn Văn A',
      partner: 'viec.co',
      contractId: '112',
      amount: '10.000.000',
      limitation: '10.000.000',
      date: '12/05/2021',
      username: 'gslixby0',
      status: 'active',
      avatar: '',
    },
    {
      id: 7,
      customer: 'Nguyễn Văn A',
      partner: 'viec.co',
      contractId: '112',
      amount: '10.000.000',
      limitation: '10.000.000',
      date: '12/05/2021',
      username: 'gslixby0',
      status: 'active',
      avatar: '',
    },
    {
      id: 8,
      customer: 'Nguyễn Văn A',
      partner: 'viec.co',
      contractId: '112',
      amount: '10.000.000',
      limitation: '10.000.000',
      date: '12/05/2021',
      username: 'gslixby0',
      status: 'active',
      avatar: '',
    },
  ],
};
/* eslint-enable */

// ------------------------------------------------
// GET: Return contracts
// ------------------------------------------------
mock.onGet('/apps/contract/contracts').reply((config) => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = '',
    perPage = 10,
    page = 1,
    sortBy = 'id',
    sortDesc = false,
    role = null,
    plan = null,
    status = null,
  } = config.params;
  /* eslint-enable */

  const queryLowered = q.toLowerCase();
  const filteredData = data.contracts.filter(
    (contract) =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (contract.username.toLowerCase().includes(queryLowered) ||
        contract.fullName.toLowerCase().includes(queryLowered)) &&
      contract.role === (role || contract.role) &&
      contract.currentPlan === (plan || contract.currentPlan) &&
      contract.status === (status || contract.status),
  );
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy));
  if (sortDesc) sortedData.reverse();

  return [
    200,
    {
      contracts: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ];
});

// ------------------------------------------------
// POST: Add new contract
// ------------------------------------------------
mock.onPost('/apps/contract/contracts').reply((config) => {
  // Get event from post data
  const { contract } = JSON.parse(config.data);

  // Assign Status
  contract.status = 'active';

  const { length } = data.contracts;
  let lastIndex = 0;
  if (length) {
    lastIndex = data.contracts[length - 1].id;
  }
  contract.id = lastIndex + 1;

  data.contracts.push(contract);

  return [201, { contract }];
});

// ------------------------------------------------
// GET: Return Single contract
// ------------------------------------------------
mock.onGet(/\/apps\/contract\/contracts\/\d+/).reply((config) => {
  // Get event id from URL
  let userId = config.url.substring(config.url.lastIndexOf('/') + 1);

  // Convert Id to number
  userId = Number(userId);

  const userIndex = data.contracts.findIndex((e) => e.id === userId);
  const contract = data.contracts[userIndex];

  if (contract) return [200, contract];
  return [404];
});
