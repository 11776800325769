import constRouter from '@/constants/constRouter';
import { auth } from '@/middleware';

export default [
  {
    path: constRouter.SYSTEM_AUTHENTICATION.path,
    name: constRouter.SYSTEM_AUTHENTICATION.name,
    component: () => import('@/views/system/permission/PermissionManagement.vue'),
    meta: {
      ...constRouter.SYSTEM_AUTHENTICATION.meta,
      pageTitle: 'Quản lý vai trò',
      breadcrumb: [
        {
          text: 'Vai trò',
          active: true,
        },
      ],
    },
  },
  {
    path: constRouter.USER_STAFF.path,
    name: constRouter.USER_STAFF.name,
    component: () => import('@/views/system/staff/staff-list/StaffList.vue'),
    meta: {
      ...constRouter.USER_STAFF.meta,
      pageTitle: 'DS nhân viên',
      breadcrumb: [
        {
          text: 'DS nhân viên',
          active: true,
        },
      ],
    },
  },
  {
    path: constRouter.USER_STAFF_NEW.path,
    name: constRouter.USER_STAFF_NEW.name,
    component: () => import('@/views/system/staff/staff-add/StaffAdd.vue'),
    meta: {
      ...constRouter.USER_STAFF_NEW.meta,
      pageTitle: 'Thêm nhân viên',
      breadcrumb: [
        {
          text: 'DS nhân viên',
          to: { name: constRouter.USER_STAFF.name },
        },
        {
          text: 'Thêm nhân viên',
          active: true,
        },
      ],
    },
  },
  {
    path: constRouter.USER_STAFF_DETAIL.path,
    name: constRouter.USER_STAFF_DETAIL.name,
    component: () => import('@/views/system/staff/staff-view/StaffView.vue'),
    meta: {
      ...constRouter.USER_STAFF_DETAIL.meta,
      pageTitle: 'Xem nhân viên',
      breadcrumb: [
        {
          text: 'DS nhân viên',
          to: { name: constRouter.USER_STAFF.name },
        },
        {
          text: 'Xem nhân viên',
          active: true,
        },
      ],
    },
  },
  {
    path: constRouter.USER_STAFF_EDIT.path,
    name: constRouter.USER_STAFF_EDIT.name,
    component: () => import('@/views/system/staff/staff-edit/StaffEdit.vue'),
    meta: {
      ...constRouter.USER_STAFF_EDIT.meta,
      pageTitle: 'Sửa nhân viên',
      breadcrumb: [
        {
          text: 'DS nhân viên',
          to: { name: constRouter.USER_STAFF.name },
        },
        {
          text: 'Sửa nhân viên',
          active: true,
        },
      ],
    },
  },
];
