export function getToken() {
  return localStorage.isLogin;
}

export function createToken() {
  localStorage.isLogin = true;
}

export function setStorePartnerToken(storePartnerData) {
  localStorage.storePartner = JSON.stringify(storePartnerData);
}

export function setUserInfo(userInfo) {
  localStorage.userInfo = JSON.stringify(userInfo);
}

export function getUserInfo() {
  const { userInfo } = localStorage;
  if (userInfo) {
    return JSON.parse(userInfo);
  }
  return null;
}

export function getStorePartnerToken() {
  const storePartnerData = localStorage.storePartner;
  if (storePartnerData) {
    return JSON.parse(storePartnerData);
  }
  return null;
}

export function destroyStorePartnerToken() {
  localStorage.removeItem('storePartner');
}

export function getFilesRedoImported() {
  const { fileRedoImported } = localStorage;
  if (fileRedoImported) {
    return JSON.parse(fileRedoImported);
  }
  return null;
}

export function addFileRedoImported(_item) {
  let fileRedoImported = getFilesRedoImported();
  if (fileRedoImported && fileRedoImported.length > 0) {
    const theFileIndex = fileRedoImported.findIndex((item) => item.id === _item.id);
    if (theFileIndex !== -1) {
      fileRedoImported[theFileIndex].times += 1;
    } else {
      fileRedoImported.push({
        id: _item.id,
        times: 1,
      });
    }
  } else {
    fileRedoImported = [
      {
        id: _item.id,
        times: 1,
      },
    ];
  }
  localStorage.fileRedoImported = JSON.stringify(fileRedoImported);
}

export function destroyToken() {
  localStorage.clear();
}

export function destroyUserInfo() {
  localStorage.removeItem('userInfo');
}

export function getPerPageToken() {
  const storedPerPage = +localStorage.per_page;
  if (storedPerPage && Number.isInteger(storedPerPage)) {
    return storedPerPage;
  }
  return 15;
}

export function setPerPageToken(perPage) {
  localStorage.per_page = perPage;
}
