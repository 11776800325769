export default {
  // Endpoints
  loginEndpoint: '/auth/login',
  profileEndpoint: '/auth/profile',
  registerEndpoint: '/auth/sign-up',
  refreshEndpoint: '/auth/refresh',
  logoutEndpoint: '/auth/logout',
  changePassEndpoint: '/auth/change-password',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
};
