import constRouter from '@/constants/constRouter';

export default [
  {
    path: constRouter.PARTNER.path,
    name: constRouter.PARTNER.name,
    component: () => import('@/views/partner/partner-list/PartnerList.vue'),
    meta: {
      ...constRouter.PARTNER.meta,
      pageTitle: 'Đối tác',
      breadcrumb: [
        {
          text: 'Đối tác',
          active: true,
        },
      ],
    },
  },
  {
    path: constRouter.PARTNER_VIEW.path,
    name: constRouter.PARTNER_VIEW.name,
    component: () => import('@/views/partner/partner-show/PartnerShow.vue'),
    meta: {
      ...constRouter.PARTNER_VIEW.meta,
    },
  },
  {
    path: constRouter.PARTNER_STORE.path,
    name: constRouter.PARTNER_STORE.name,
    component: () => import('@/views/partner/partner-store/PartnerStore.vue'),
    meta: {
      ...constRouter.PARTNER.meta,
      pageTitle: 'Thêm đối tác',
      breadcrumb: [
        {
          text: 'Đối tác',
          to: { name: constRouter.PARTNER.name },
        },
        {
          text: 'Thêm đối tác',
          active: true,
        },
      ],
    },
  },
  {
    path: constRouter.PARTNER_EDIT.path,
    name: constRouter.PARTNER_EDIT.name,
    component: () => import('@/views/partner/partner-edit/PartnerEdit.vue'),
    meta: {
      ...constRouter.PARTNER.meta,
      pageTitle: 'Sửa đối tác',
      breadcrumb: [
        {
          text: 'Đối tác',
          to: { name: constRouter.PARTNER.name },
        },
        {
          text: 'Sửa đối tác',
          active: true,
        },
      ],
    },
  },
];
