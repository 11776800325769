import Vue from 'vue';
import Vuex from 'vuex';

import useJwt from '@/auth/jwt/useJwt';

// Modules
import ecommerceStoreModule from '@/views/theme/apps/e-commerce/eCommerceStoreModule';
import { handleUserProfile } from '@/@core/utils/utils';
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';

Vue.use(Vuex);

const modules = { app, appConfig, verticalMenu, 'app-ecommerce': ecommerceStoreModule };

// Auto-load all store.ts in folder views
const requireComponent = require.context('../views', true, /store.js$/);
requireComponent.keys().forEach((fileName) => {
  // Get component config
  const componentConfig = requireComponent(fileName);
  modules[componentConfig.name] = componentConfig.default;
  modules[componentConfig.name].namespaced = true;
});

export default new Vuex.Store({
  state: {
    user: null,
  },
  mutations: {
    SET_USER_INFO(state, data) {
      state.user = data;
      handleUserProfile(data);
      console.log({ SET_USER_INFO: state.user });
    },
    SET_LOADING(state, data) {
      state.loading = data;
    },
  },
  actions: {
    async fetchUserInfo({ commit }) {
      commit('SET_LOADING', true);
      try {
        const { data } = await useJwt.profile();
        commit('SET_USER_INFO', data.data);
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
      commit('SET_LOADING', false);
    },
    logout({ commit }) {
      commit('LOGOUT');
    },
  },
  getters: {
    getUser(state) {
      console.log('getUser', state.user);
      return state.user;
    },
  },
  modules,
  strict: process.env.DEV,
});
