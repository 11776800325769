import constRouter from '@/constants/constRouter';

export default [
  {
    path: constRouter.TEAM.path,
    name: constRouter.TEAM.name,
    component: () => import('@/views/team/team-list/TeamList.vue'),
    meta: {
      ...constRouter.TEAM.meta,
      pageTitle: 'Đội',
      breadcrumb: [
        {
          text: 'Đội',
          active: true,
        },
      ],
    },
  },
  {
    path: constRouter.TEAM_STORE.path,
    name: constRouter.TEAM_STORE.name,
    component: () => import('@/views/team/team-add/TeamAdd.vue'),
    meta: {
      ...constRouter.TEAM.meta,
      pageTitle: 'Đội',
      breadcrumb: [
        {
          text: 'Đội',
          to: { name: constRouter.TEAM.name },
        },
        {
          text: 'Thêm Đội',
          active: true,
        },
      ],
    },
  },
  {
    path: constRouter.TEAM_EDIT.path,
    name: constRouter.TEAM_EDIT.name,
    component: () => import('@/views/team/team-edit/TeamEdit.vue'),
    meta: {
      ...constRouter.TEAM.meta,
      pageTitle: 'Đội',
      breadcrumb: [
        {
          text: 'Đội',
          to: { name: constRouter.TEAM.name },
        },
        {
          text: 'Sửa Đội',
          active: true,
        },
      ],
    },
  },
  {
    path: constRouter.TEAM_VIEW.path,
    name: constRouter.TEAM_VIEW.name,
    component: () => import('@/views/team/team-view/TeamView.vue'),
    meta: {
      ...constRouter.TEAM.meta,
      pageTitle: 'Đội',
      breadcrumb: [
        {
          text: 'Đội',
          to: { name: constRouter.TEAM.name },
        },
        {
          text: 'Xem Đội',
          active: true,
        },
      ],
    },
  },
];
