export default {
  AUTH: {
    GET_v1_auth_profile: {
      resource: 'auth',
      action: 'GET.v1/auth/profile',
    },
  },
  ACTION_CODE: {
    GET_v1_action_codes_partners: {
      resource: 'action-codes',
      action: 'GET.v1/action-codes/partners',
    },
    GET_v1_action_codes: {
      resource: 'action-codes',
      action: 'GET.v1/action-codes',
    },
    POST_v1_action_codes: {
      resource: 'action-codes',
      action: 'POST.v1/action-codes',
    },
    GET_v1_action_codes__action_code_id: {
      resource: 'action-codes',
      action: 'GET.v1/action-codes/{action_code}',
    },
    PUT_v1_action_codes__action_code_id: {
      resource: 'action-codes',
      action: 'PUT.v1/action-codes/{action_code}',
    },
    DELETE_v1_action_codes__action_code_id: {
      resource: 'action-codes',
      action: 'DELETE.v1/action-codes/{action_code}',
    },
  },
  ADVANCE_FILTER: {
    GET_v1_advance_filter_statuses: {
      resource: 'advance-filter',
      action: 'GET.v1/advance-filter/statuses',
    },
    GET_v1_advance_filter_partners: {
      resource: 'advance-filter',
      action: 'GET.v1/advance-filter/partners',
    },
    GET_v1_advance_filter_campaigns: {
      resource: 'advance-filter',
      action: 'GET.v1/advance-filter/campaigns',
    },
    GET_v1_advance_filter_teams: {
      resource: 'advance-filter',
      action: 'GET.v1/advance-filter/teams',
    },
    GET_v1_advance_filter_users: {
      resource: 'advance-filter',
      action: 'GET.v1/advance-filter/users',
    },
  },
  ASSIGNMENT: {
    CONTROLLER_allow_assignment: {
      resource: 'assignment',
      action: 'CONTROLLER.allow_assignment',
    },
    CONTROLLER_assignment_all_teams: {
      resource: 'assignment',
      action: 'CONTROLLER.assignment_all_teams',
    },
    GET_v1_assignment_campaigns: {
      resource: 'assignment',
      action: 'GET.v1/assignment/campaigns',
    },
    GET_v1_assignment_campaigns__campaign__teams: {
      resource: 'assignment',
      action: 'GET.v1/assignment/campaigns/{campaign}/teams',
    },
    POST_v1_assignment_campaigns__campaign__teams: {
      resource: 'assignment',
      action: 'POST.v1/assignment/campaigns/{campaign}/teams',
    },
    GET_v1_assignment_teams: {
      resource: 'assignment',
      action: 'GET.v1/assignment/teams',
    },
    GET_v1_assignment_teams__team__campaigns: {
      resource: 'assignment',
      action: 'GET.v1/assignment/teams/{team}/campaigns',
    },
    GET_v1_assignment_teams__team__users: {
      resource: 'assignment',
      action: 'GET.v1/assignment/teams/{team}/users',
    },
    POST_v1_assignment_teams__team__users: {
      resource: 'assignment',
      action: 'POST.v1/assignment/teams/{team}/users',
    },
  },
  CAMPAIGN: {
    GET_v1_campaigns: {
      resource: 'campaigns',
      action: 'GET.v1/campaigns',
    },
    POST_v1_campaigns: {
      resource: 'campaigns',
      action: 'POST.v1/campaigns',
    },
    GET_v1_campaigns__campaign_id: {
      resource: 'campaigns',
      action: 'GET.v1/campaigns/{campaign}',
    },
    PUT_v1_campaigns__campaign_id: {
      resource: 'campaigns',
      action: 'PUT.v1/campaigns/{campaign}',
    },
    DELETE_v1_campaigns__campaign_id: {
      resource: 'campaigns',
      action: 'DELETE.v1/campaigns/{campaign}',
    },
    GET_v1_campaigns__campaign__teams: {
      resource: 'campaigns',
      action: 'GET.v1/campaigns/{campaign}/teams',
    },
    POST_v1_campaigns__campaign__autocall: {
      resource: 'campaigns',
      action: 'POST.v1/campaigns/{campaign}/autocall',
    },
    POST_v1_campaigns__campaign__transfer: {
      resource: 'campaigns',
      action: 'POST.v1/campaigns/{campaign}/transfer',
    },
    GET_v1_campaigns_teams: {
      resource: 'campaigns',
      action: 'GET.v1/campaigns/teams',
    },
  },
  CONTRACT: {
    GET_v1_contracts: {
      resource: 'contracts',
      action: 'GET.v1/contracts',
    },
    POST_v1_contracts: {
      resource: 'contracts',
      action: 'POST.v1/contracts',
    },
    GET_v1_contracts__contract_id: {
      resource: 'contracts',
      action: 'GET.v1/contracts/{contract}',
    },
    PUT_v1_contracts__contract_id: {
      resource: 'contracts',
      action: 'PUT.v1/contracts/{contract}',
    },
    DELETE_v1_contracts__contract_id: {
      resource: 'contracts',
      action: 'DELETE.v1/contracts/{contract}',
    },
    GET_v1_contracts_export__uploadFile_id: {
      resource: 'contracts',
      action: 'GET.v1/contracts/export/{uploadFile}',
    },
    GET_v1_contracts_export_full__uploadFile_id: {
      resource: 'contracts',
      action: 'GET.v1/contracts/export-full/{uploadFile}',
    },
    GET_v1_contracts__contract__actions: {
      resource: 'contracts',
      action: 'GET.v1/contracts/{contract}/actions',
    },
    GET_v1_contracts__contract__documents: {
      resource: 'contracts',
      action: 'GET.v1/contracts/{contract}/documents',
    },
    GET_v1_contracts__contract__documents_download__documentId___ext_: {
      resource: 'contracts',
      action: 'GET.v1/contracts/{contract}/documents/download/{documentId}.{ext}',
    },
    GET_v1_contracts__contract__documents_for_field: {
      resource: 'contracts',
      action: 'GET.v1/contracts/{contract}/documents/for-field',
    },
    POST_v1_contracts__contract__documents_reload: {
      resource: 'contracts',
      action: 'POST.v1/contracts/{contract}/documents/reload',
    },
    GET_v1_contracts__contract__change_logs: {
      resource: 'contracts',
      action: 'GET.v1/contracts/{contract}/change-logs',
    },
    POST_v1_contracts__contract__update_result: {
      resource: 'contracts',
      action: 'POST.v1/contracts/{contract}/update-result',
    },
    GET_v1_contracts__contract__customer_contacts: {
      resource: 'contracts',
      action: 'GET.v1/contracts/{contract}/customer-contacts',
    },
    POST_v1_contracts__contract__customer_contacts: {
      resource: 'contracts',
      action: 'POST.v1/contracts/{contract}/customer-contacts',
    },
    POST_v1_contracts_action_assignment: {
      resource: 'contracts',
      action: 'POST.v1/contracts/action/assignment',
    },
    GET_v1_contracts__contract__calls: {
      resource: 'contracts',
      action: 'GET.v1/contracts/{contract}/calls',
    },
    GET_v1_contracts__contract__other_contracts: {
      resource: 'contracts',
      action: 'GET.v1/contracts/{contract}/other-contracts',
    },
    GET_v1_contracts__contract__next: {
      resource: 'contracts',
      action: 'GET.v1/contracts/{contract}/next',
    },
  },
  CUSTOMER: {
    GET_v1_customers: {
      resource: 'customers',
      action: 'GET.v1/customers',
    },
    POST_v1_customers: {
      resource: 'customers',
      action: 'POST.v1/customers',
    },
    GET_v1_customers__customer_id: {
      resource: 'customers',
      action: 'GET.v1/customers/{customer}',
    },
    PUT_v1_customers__customer_id: {
      resource: 'customers',
      action: 'PUT.v1/customers/{customer}',
    },
    DELETE_v1_customers__customer_id: {
      resource: 'customers',
      action: 'DELETE.v1/customers/{customer}',
    },
  },
  DASHBOARD: {
    GET_v1_dashboard_count_contracts_by_status: {
      resource: 'dashboard',
      action: 'GET.v1/dashboard/count-contracts-by-status',
    },
    GET_v1_dashboard_count_contracts_by_partner: {
      resource: 'dashboard',
      action: 'GET.v1/dashboard/count-contracts-by-partner',
    },
    GET_v1_dashboard_user_oncall_time: {
      resource: 'dashboard',
      action: 'GET.v1/dashboard/user-oncall-time',
    },
    CONTROLLER_view_all_on_call: {
      resource: 'dashboard',
      action: 'CONTROLLER.view_all_on_call',
    },
  },
  GENERAL: {
    GET_v1_general_structure: {
      resource: 'general',
      action: 'GET.v1/general/structure',
    },
    GET_v1_general_types: {
      resource: 'general',
      action: 'GET.v1/general/types',
    },
    GET_v1_general_contract_statuses: {
      resource: 'general',
      action: 'GET.v1/general/contract-statuses',
    },
    GET_v1_general_person_contacts: {
      resource: 'general',
      action: 'GET.v1/general/person-contacts',
    },
    GET_v1_general_place_contacts: {
      resource: 'general',
      action: 'GET.v1/general/place-contacts',
    },
  },
  IMPORT: {
    GET_v1_imports: {
      resource: 'imports',
      action: 'GET.v1/imports',
    },
    POST_v1_imports: {
      resource: 'imports',
      action: 'POST.v1/imports',
    },
    GET_v1_imports__import_id: {
      resource: 'imports',
      action: 'GET.v1/imports/{import}',
    },
    PUT_v1_imports__import_id: {
      resource: 'imports',
      action: 'PUT.v1/imports/{import}',
    },
    DELETE_v1_imports__import_id: {
      resource: 'imports',
      action: 'DELETE.v1/imports/{import}',
    },
    GET_v1_imports__uploadFile__errors: {
      resource: 'imports',
      action: 'GET.v1/imports/{uploadFile}/errors',
    },
    GET_v1_imports__uploadFile__retry: {
      resource: 'imports',
      action: 'GET.v1/imports/{uploadFile}/retry',
    },
  },
  PARTNER: {
    GET_v1_partners: {
      resource: 'partners',
      action: 'GET.v1/partners',
    },
    POST_v1_partners: {
      resource: 'partners',
      action: 'POST.v1/partners',
    },
    GET_v1_partners__partner_id: {
      resource: 'partners',
      action: 'GET.v1/partners/{partner}',
    },
    PUT_v1_partners__partner_id: {
      resource: 'partners',
      action: 'PUT.v1/partners/{partner}',
    },
    DELETE_v1_partners__partner_id: {
      resource: 'partners',
      action: 'DELETE.v1/partners/{partner}',
    },
  },
  PERMISSION: {
    GET_v1_permissions: {
      resource: 'permissions',
      action: 'GET.v1/permissions',
    },
    POST_v1_permissions: {
      resource: 'permissions',
      action: 'POST.v1/permissions',
    },
    GET_v1_permissions__permission_id: {
      resource: 'permissions',
      action: 'GET.v1/permissions/{permission}',
    },
    PUT_v1_permissions__permission_id: {
      resource: 'permissions',
      action: 'PUT.v1/permissions/{permission}',
    },
    DELETE_v1_permissions__permission_id: {
      resource: 'permissions',
      action: 'DELETE.v1/permissions/{permission}',
    },
  },
  ROLE: {
    GET_v1_roles: {
      resource: 'roles',
      action: 'GET.v1/roles',
    },
    POST_v1_roles: {
      resource: 'roles',
      action: 'POST.v1/roles',
    },
    GET_v1_roles__role_id: {
      resource: 'roles',
      action: 'GET.v1/roles/{role}',
    },
    PUT_v1_roles__role_id: {
      resource: 'roles',
      action: 'PUT.v1/roles/{role}',
    },
    DELETE_v1_roles__role_id: {
      resource: 'roles',
      action: 'DELETE.v1/roles/{role}',
    },
    GET_v1_roles__role__permissions: {
      resource: 'roles',
      action: 'GET.v1/roles/{role}/permissions',
    },
  },
  STRINGEE: {
    POST_v1_stringee_client_token: {
      resource: 'stringee',
      action: 'POST.v1/stringee/client-token',
    },
    POST_v1_stringee__contract__make_call: {
      resource: 'stringee',
      action: 'POST.v1/stringee/{contract}/make-call',
    },
    POST_v1_stringee__uid__stop_call: {
      resource: 'stringee',
      action: 'POST.v1/stringee/{uid}/stop-call',
    },
    POST_v1_stringee_events: {
      resource: 'stringee',
      action: 'POST.v1/stringee/events',
    },
  },
  TEAM: {
    GET_v1_teams: {
      resource: 'teams',
      action: 'GET.v1/teams',
    },
    POST_v1_teams: {
      resource: 'teams',
      action: 'POST.v1/teams',
    },
    GET_v1_teams__team_id: {
      resource: 'teams',
      action: 'GET.v1/teams/{team}',
    },
    PUT_v1_teams__team_id: {
      resource: 'teams',
      action: 'PUT.v1/teams/{team}',
    },
    DELETE_v1_teams__team_id: {
      resource: 'teams',
      action: 'DELETE.v1/teams/{team}',
    },
    GET_v1_teams__team__users: {
      resource: 'teams',
      action: 'GET.v1/teams/{team}/users',
    },
    GET_v1_teams__team__campaigns: {
      resource: 'teams',
      action: 'GET.v1/teams/{team}/campaigns',
    },
    GET_v1_teams_campaigns: {
      resource: 'teams',
      action: 'GET.v1/teams/campaigns',
    },
  },
  USER: {
    GET_v1_users: {
      resource: 'users',
      action: 'GET.v1/users',
    },
    POST_v1_users: {
      resource: 'users',
      action: 'POST.v1/users',
    },
    GET_v1_users__user_id: {
      resource: 'users',
      action: 'GET.v1/users/{user}',
    },
    PUT_v1_users__user_id: {
      resource: 'users',
      action: 'PUT.v1/users/{user}',
    },
    DELETE_v1_users__user_id: {
      resource: 'users',
      action: 'DELETE.v1/users/{user}',
    },
    GET_v1_users__user__permissions: {
      resource: 'users',
      action: 'GET.v1/users/{user}/permissions',
    },
    POST_v1_users__user__lock: {
      resource: 'users',
      action: 'POST.v1/users/{user}/lock',
    },
  },
  UPLOAD_DATA: {
    POST_v1_upload_data_actions: {
      resource: 'imports',
      action: 'POST.v1/imports/actions',
    },
    POST_v1_upload_data_skips: {
      resource: 'imports',
      action: 'POST.v1/imports/skips',
    },
  },
};
