import ability from '@/libs/acl/ability';
import router from '@/router';
// eslint-disable-next-line object-curly-newline
import { reactive, getCurrentInstance, watch, toRefs } from '@vue/composition-api';
import Vue from 'vue';

export const isObject = (obj) => typeof obj === 'object' && obj !== null;

export const isToday = (date) => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

export function removeViTones(text) {
  return text
    .replace(/\s\s+/g, ' ') // replace multiple spaces with a single space
    .replace(/đ/g, 'd')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
}

export const numberWithCommas = (valNumber) => {
  // const { valNumber: resolvedRoute } = router.resolve(valNumber)
  if (valNumber) {
    valNumber = valNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
  return valNumber;
};

export function numberWithoutCommas(_money) {
  let money = _money;
  if (money) {
    money = money.replace(/\./g, '');
    if (Number.isInteger(+money)) {
      return +money;
    }
  }
  return null;
}

export function formatPhoneNumber(val) {
  if (!val) {
    return null;
  }
  if (val.toString().substring(0, 2) === '84' && val.toString().length > 10) {
    val = val.toString().substring(2);
  }
  return `0${val * 1}`;
}

export function formatPhoneNumber84(val) {
  if (val.length === 10 && val[0] === '0') {
    return `84${val.slice(1)}`;
  }
  if (val.length === 9) {
    return `84${val}`;
  }
  return val;
}

const getRandomFromArray = (array) => array[Math.floor(Math.random() * array.length)];

// ? Light and Dark variant is not included
// prettier-ignore
export const getRandomBsVariant = () => getRandomFromArray(['primary', 'secondary', 'success', 'warning', 'danger', 'info'])

export const isDynamicRouteActive = (route) => {
  const { route: resolvedRoute } = router.resolve(route);
  return resolvedRoute.path === router.currentRoute.path;
};

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
export const useRouter = () => {
  const vm = getCurrentInstance().proxy;
  const state = reactive({
    route: vm.$route,
  });

  watch(
    () => vm.$route,
    (r) => {
      state.route = r;
    },
  );

  return { ...toRefs(state), router: vm.$router };
};

export function shortName(fullName, totalLastWord = 2) {
  if (!fullName) {
    return fullName;
  }

  return fullName
    .trim()
    .replace(/\s+/g, ' ')
    .split(' ')
    .slice(-1 * totalLastWord)
    .join(' ');
}

export const handleUserProfile = (userData) => {
  if (userData.is_superadmin) {
    userData.ability = [
      {
        action: 'manage',
        subject: 'all',
      },
    ];
  } else {
    userData.ability = userData.permissions.map((per) => ({
      action: per.code,
      subject: per.group,
    }));
  }
  localStorage.setItem('userData', JSON.stringify(userData));
  ability.update(userData.ability);
};
