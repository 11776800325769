import constRouter from '@/constants/constRouter';

export default [
  {
    path: constRouter.ACTION_CODE.path,
    name: constRouter.ACTION_CODE.name,
    component: () => import('@/views/action-code/action-code-list/ActionCodeList.vue'),
    meta: {
      ...constRouter.ACTION_CODE.meta,
      pageTitle: 'Mã tác động',
      breadcrumb: [
        {
          text: 'Mã tác động',
          active: true,
        },
      ],
    },
  },
  {
    path: constRouter.ACTION_CODE_STORE.path,
    name: constRouter.ACTION_CODE_STORE.name,
    component: () => import('@/views/action-code/action-code-add/ActionCodeAdd.vue'),
    meta: {
      ...constRouter.ACTION_CODE.meta,
      pageTitle: 'Mã tác động',
      breadcrumb: [
        {
          text: 'Mã tác động',
          to: { name: constRouter.ACTION_CODE.name },
        },
        {
          text: 'Thêm mã tác động',
          active: true,
        },
      ],
    },
  },
  {
    path: constRouter.ACTION_CODE_EDIT.path,
    name: constRouter.ACTION_CODE_EDIT.name,
    component: () => import('@/views/action-code/action-code-edit/ActionCodeEdit.vue'),
    meta: {
      ...constRouter.ACTION_CODE.meta,
      pageTitle: 'Mã tác động',
      breadcrumb: [
        {
          text: 'Mã tác động',
          to: { name: constRouter.ACTION_CODE.name },
        },
        {
          text: 'Sửa mã tác động',
          active: true,
        },
      ],
    },
  },
  {
    path: constRouter.ACTION_CODE_VIEW.path,
    name: constRouter.ACTION_CODE_VIEW.name,
    component: () => import('@/views/action-code/action-code-view/ActionCodeView.vue'),
    meta: {
      ...constRouter.ACTION_CODE_VIEW.meta,
      pageTitle: 'Mã tác động',
      breadcrumb: [
        {
          text: 'Mã tác động',
          to: { name: constRouter.ACTION_CODE_VIEW.name },
        },
        {
          text: 'Xem mã tác động',
          active: true,
        },
      ],
    },
  },
];
