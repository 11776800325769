import constRouter from '@/constants/constRouter';

export default [
  {
    path: constRouter.UPLOAD_ACTIONS.path,
    name: constRouter.UPLOAD_ACTIONS.name,
    component: () => import('@/views/upload/UploadAction.vue'),
    meta: {
      ...constRouter.UPLOAD_ACTIONS.meta,
      breadcrumb: [
        {
          text: 'Upload Data',
        },
        {
          text: 'Upload Action',
          active: true,
        },
      ],
    },
  },
  {
    path: constRouter.UPLOAD_SKIPS.path,
    name: constRouter.UPLOAD_SKIPS.name,
    component: () => import('@/views/upload/UploadSkip.vue'),
    meta: {
      ...constRouter.UPLOAD_SKIPS.meta,
      breadcrumb: [
        {
          text: 'Upload Data',
        },
        {
          text: 'Upload Skip',
          active: true,
        },
      ],
    },
  },
];
