import constRouter from '@/constants/constRouter';
import { auth } from '@/middleware';

export default [
  {
    path: constRouter.SETTING.path,
    name: constRouter.SETTING.name,
    component: () => import('@/views/account-setting/AccountSetting.vue'),
    meta: {
      middleware: [auth],
    },
  },
];
