import permissions from './permissions';

export default {
  // =======================AUTH=======================
  AUTH_LOGIN: {
    path: '/auth/login',
    name: 'auth.login',
    meta: {
      action: 'read',
      resource: 'unAuth',
      redirectIfLoggedIn: true,
    },
  },

  // =======================SETTING=======================
  SETTING: {
    path: '/account-setting',
    name: 'account-setting',
  },

  // =======================DASHBOARD=======================
  DASHBOARD: {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      ...permissions.AUTH.GET_v1_auth_profile,
    },
  },

  // =======================CONTRACT=======================
  CONTRACT: {
    path: '/contract',
    name: 'contract',
    meta: {
      ...permissions.CONTRACT.GET_v1_contracts,
    },
  },

  CONTRACT_VIEW: {
    path: '/contract/:id',
    name: 'contract.view',
    meta: {
      ...permissions.CONTRACT.GET_v1_contracts__contract_id,
    },
  },

  // =======================IMPORT=======================
  IMPORT: {
    path: '/imports',
    name: 'imports.list',
    meta: {
      ...permissions.IMPORT.GET_v1_imports,
    },
  },
  IMPORT_VIEW: {
    path: '/imports/:id/details-error',
    name: 'imports.view.details-error',
    meta: {
      ...permissions.IMPORT.GET_v1_imports__uploadFile__errors,
    },
  },
  IMPORT_STORE: {
    path: '/imports/store',
    name: 'imports.store',
    meta: {
      ...permissions.IMPORT.POST_v1_imports,
    },
  },

  // =======================CAMPAIGN=======================
  CAMPAIGN: {
    path: '/campaigns',
    name: 'campaigns',
    meta: {
      ...permissions.CAMPAIGN.GET_v1_campaigns,
    },
  },
  CAMPAIGN_STORE: {
    path: '/campaigns/store',
    name: 'campaigns.store',
    meta: {
      ...permissions.CAMPAIGN.POST_v1_campaigns,
    },
  },
  CAMPAIGN_EDIT: {
    path: '/campaigns/:id/edit',
    name: 'campaigns.edit',
    meta: {
      ...permissions.CAMPAIGN.PUT_v1_campaigns__campaign_id,
    },
  },
  CAMPAIGN_VIEW: {
    path: '/campaigns/:id/view',
    name: 'campaigns.view',
    meta: {
      ...permissions.CAMPAIGN.GET_v1_campaigns__campaign_id,
    },
  },

  // =======================TEAM=======================
  TEAM: {
    path: '/teams',
    name: 'teams',
    meta: {
      ...permissions.TEAM.GET_v1_teams,
    },
  },
  TEAM_STORE: {
    path: '/teams/store',
    name: 'teams.store',
    meta: {
      ...permissions.TEAM.POST_v1_teams,
    },
  },
  TEAM_EDIT: {
    path: '/teams/:id/edit',
    name: 'teams.edit',
    meta: {
      ...permissions.TEAM.PUT_v1_teams__team_id,
    },
  },
  TEAM_VIEW: {
    path: '/teams/:id/view',
    name: 'teams.view',
    meta: {
      ...permissions.TEAM.GET_v1_teams__team_id,
    },
  },

  // =======================ACTION_CODE=======================
  ACTION_CODE: {
    path: '/action-codes',
    name: 'action-codes',
    meta: {
      ...permissions.ACTION_CODE.GET_v1_action_codes,
    },
  },
  ACTION_CODE_STORE: {
    path: '/action-codes/store',
    name: 'action-codes.store',
    meta: {
      ...permissions.ACTION_CODE.POST_v1_action_codes,
    },
  },
  ACTION_CODE_EDIT: {
    path: '/action-codes/:id/edit',
    name: 'action-codes.edit',
    meta: {
      ...permissions.ACTION_CODE.PUT_v1_action_codes__action_code_id,
    },
  },
  ACTION_CODE_VIEW: {
    path: '/action-codes/:id/view',
    name: 'action-codes.view',
    meta: {
      ...permissions.ACTION_CODE.GET_v1_action_codes__action_code_id,
    },
  },

  // =======================ACTION_CODE=======================
  ASSIGNMENT: {
    path: '/assignments',
    name: 'assignments',
    meta: {
      ...permissions.ASSIGNMENT.CONTROLLER_allow_assignment,
    },
  },

  // =======================PARTNER=======================
  PARTNER: {
    path: '/partners',
    name: 'partners',
    meta: {
      ...permissions.PARTNER.GET_v1_partners,
    },
  },

  PARTNER_VIEW: {
    path: '/partners/:id/show',
    name: 'partners.show',
    meta: {
      ...permissions.PARTNER.GET_v1_partners__partner_id,
    },
  },

  PARTNER_STORE: {
    path: '/partners/store',
    name: 'partners.store',
    meta: {
      ...permissions.PARTNER.POST_v1_partners,
    },
  },

  PARTNER_EDIT: {
    path: '/partners/:id/edit',
    name: 'partners.edit',
    meta: {
      ...permissions.PARTNER.PUT_v1_partners__partner_id,
    },
  },

  // =======================CUSTOMER=======================
  CUSTOMER: {
    path: '/customer',
    name: 'customer',
    meta: {
      ...permissions.CUSTOMER.GET_v1_customers,
    },
  },
  CUSTOMER_VIEW: {
    path: '/customer/:id',
    name: 'customer.view',
    meta: {
      ...permissions.CUSTOMER.GET_v1_customers__customer_id,
    },
  },

  // =======================SYSTEM=======================
  SYSTEM: {
    path: '/system',
    name: 'system',
    meta: {
      action: 'read',
      resource: 'Auth',
    },
  },
  SYSTEM_AUTHENTICATION: {
    path: '/system/permission',
    name: 'system.permission',
    meta: {
      ...permissions.PERMISSION.GET_v1_permissions,
    },
  },

  // =======================USER=======================
  USER_STAFF: {
    path: '/user/staff',
    name: 'user.staff',
    meta: {
      ...permissions.USER.GET_v1_users,
    },
  },
  USER_STAFF_NEW: {
    path: '/user/staff/new',
    name: 'user.staff.new',
    meta: {
      ...permissions.USER.POST_v1_users,
    },
  },
  USER_STAFF_EDIT: {
    path: '/user/staff/edit/:id',
    name: 'user.staff.edit',
    meta: {
      ...permissions.USER.PUT_v1_users__user_id,
    },
  },
  USER_STAFF_DETAIL: {
    path: '/user/staff/:id',
    name: 'user.staff.detail',
    meta: {
      ...permissions.USER.GET_v1_users__user_id,
    },
  },
  UPLOAD_ACTIONS: {
    path: '/upload-data/actions',
    name: 'upload-data.actions',
    meta: {
      ...permissions.UPLOAD_DATA.POST_v1_upload_data_actions,
    },
  },
  UPLOAD_SKIPS: {
    path: '/upload-data/skips',
    name: 'upload-data.skips',
    meta: {
      ...permissions.UPLOAD_DATA.POST_v1_upload_data_skips,
    },
  },
};
