import Vue from 'vue';
import VueRouter from 'vue-router';

// Routes
import constRouter from '@/constants/constRouter';
import { canNavigate } from '@/libs/acl/routeProtection';
import { isUserLoggedIn } from '@/auth/utils';
import theme from './theme/index';

Vue.use(VueRouter);

// General route
let routes = [
  { path: '/', redirect: { name: constRouter.DASHBOARD.name } },
  ...theme,
  {
    path: '*',
    redirect: 'error-404',
  },
];

// Auto-load all router.js in folder views
const requireComponent = require.context('../views', true, /router.js$/);
requireComponent.keys().forEach((fileName) => {
  // Get component config
  const componentConfig = requireComponent(fileName);
  routes = routes.concat(componentConfig.default || componentConfig);
});

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = isUserLoggedIn();
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    // ! We updated login route name here from `auth-login` to `login` in starter-kit
    if (!isLoggedIn) return next({ name: 'auth.login' });

    // If logged in => not authorized
    // return next({ name: 'misc-not-authorized' });
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/');
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

Vue.$router = router;
export default router;
