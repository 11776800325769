import ConstRouter from '@/constants/constRouter';
import jwt from '@/auth/jwt/useJwt';

export function checkNotLogin({ next }) {
  if (jwt.getToken()) {
    next({
      name: ConstRouter.DASHBOARD.name,
    });
  }
  next();
}

export function auth({ next }) {
  if (!jwt.getToken()) {
    next({
      name: ConstRouter.AUTH_LOGIN.name,
    });
  }
  return next();
}
