import constRouter from '@/constants/constRouter';

export default [
  {
    path: constRouter.ASSIGNMENT.path,
    name: constRouter.ASSIGNMENT.name,
    component: () => import('@/views/assignment/Assignment.vue'),
    meta: {
      ...constRouter.ASSIGNMENT.meta,
      pageTitle: 'Phân công',
      breadcrumb: [
        {
          text: 'Phân công',
          active: true,
        },
      ],
    },
  },
];
