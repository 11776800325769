import constRouter from '@/constants/constRouter';

export default [
  {
    path: constRouter.IMPORT.path,
    name: constRouter.IMPORT.name,
    component: () => import('@/views/import/import-list/ImportList.vue'),
    meta: {
      ...constRouter.PARTNER.meta,
      pageTitle: 'Import',
      breadcrumb: [
        {
          text: 'Import',
          active: true,
        },
      ],
    },
  },
  {
    path: constRouter.IMPORT_VIEW.path,
    name: constRouter.IMPORT_VIEW.name,
    component: () => import('@/views/import/import-list-error/ImportListError.vue'),
    meta: {
      ...constRouter.IMPORT_VIEW.meta,
      pageTitle: 'Import',
      breadcrumb: [
        {
          text: 'Import',
          to: { name: constRouter.IMPORT.name },
        },
        {
          text: 'Chi tiết lỗi',
          active: true,
        },
      ],
    },
  },
  {
    path: constRouter.IMPORT_STORE.path,
    name: constRouter.IMPORT_STORE.name,
    component: () => import('@/views/import/import-list/ImportFile.vue'),
    meta: {
      ...constRouter.PARTNER.meta,
      pageTitle: 'Import file',
      breadcrumb: [
        {
          text: 'Import',
          to: { name: constRouter.IMPORT.name },
        },
        {
          text: 'Import file',
          active: true,
        },
      ],
    },
  },
];
