import chartsMaps from './charts-maps';
import pages from './pages';

const data = [
  // ...apps,
  ...chartsMaps,
  // ...others,
  ...pages,
  // ...dashboard,
  // ...formsTables,
  // ...uiElements,
];

export default data;
