import * as moment from 'moment';
import { numberWithCommas } from '@core/utils/utils';

const formatLikeRules = [
  {
    regex: '^amt_',
    func: (value) => numberWithCommas(value),
  },
  {
    regex: '^cnt_',
    func: (value) => numberWithCommas(value),
  },
];

const formatRules = {
  appointment_amount: (value) => numberWithCommas(value),
  person_contact: (value) => `person_contact.${value}`,
  place_contact: (value) => `place_contact.${value}`,
  appointment_date: (value) => (value ? moment(value).format('DD-MM-YYYY') : ''),
  status: (value) => `contracts.status.${value}`,
  signed_date: (value) => (value ? moment(value).format('DD-MM-YYYY') : ''),
  repayment_actually_first: (value) => (value ? moment(value).format('DD-MM-YYYY') : ''),
  repayment_actually_last: (value) => (value ? moment(value).format('DD-MM-YYYY') : ''),
  principal_current: (value) => numberWithCommas(value),
  current_repayment_amount: (value) => numberWithCommas(value),
  total_postdue_amount: (value) => numberWithCommas(value),
  interest_punish: (value) => numberWithCommas(value),
  principal_out_of_date: (value) => numberWithCommas(value),
  interest_paid: (value) => numberWithCommas(value),
  last_repayment_amount: (value) => numberWithCommas(value),
  paid_amount: (value) => numberWithCommas(value),
  interest: (value) => `${value}%`,
  interest_current: (value) => numberWithCommas(value),
  monthly_income: (value) => numberWithCommas(value),
  princomp_recd: (value) => numberWithCommas(value),
  int_overdue: (value) => numberWithCommas(value),
  prin_not_overdue: (value) => numberWithCommas(value),
  int_not_overdue: (value) => numberWithCommas(value),
  repayment_amount: (value) => numberWithCommas(value),
  repayment_date: (value) => (value ? moment(value).format('DD-MM-YYYY') : ''),
  repayment_total: (value) => numberWithCommas(value),
};

export default function install(Vue, options) {
  Vue.prototype.$formatter = (field, value) => {
    if (formatRules[field]) {
      return formatRules[field](value);
    }

    for (const rule of formatLikeRules) {
      const regex = new RegExp(rule.regex);
      if (regex.test(field)) {
        return rule.func(value);
      }
    }
    return value;
  };
}
