import constRouter from '@/constants/constRouter';

export default [
  {
    path: constRouter.CAMPAIGN.path,
    name: constRouter.CAMPAIGN.name,
    component: () => import('@/views/campaign/campaign-list/CampaignList.vue'),
    meta: {
      ...constRouter.CAMPAIGN.meta,
      pageTitle: 'Chiến dịch',
      breadcrumb: [
        {
          text: 'Chiến dịch',
          active: true,
        },
      ],
    },
  },
  {
    path: constRouter.CAMPAIGN_STORE.path,
    name: constRouter.CAMPAIGN_STORE.name,
    component: () => import('@/views/campaign/campaign-add/CampaignAdd.vue'),
    meta: {
      ...constRouter.CAMPAIGN.meta,
      pageTitle: 'Chiến dịch',
      breadcrumb: [
        {
          text: 'Chiến dịch',
          to: { name: constRouter.CAMPAIGN.name },
        },
        {
          text: 'Thêm chiến dịch',
          active: true,
        },
      ],
    },
  },
  {
    path: constRouter.CAMPAIGN_EDIT.path,
    name: constRouter.CAMPAIGN_EDIT.name,
    component: () => import('@/views/campaign/campaign-edit/CampaignEdit.vue'),
    meta: {
      ...constRouter.CAMPAIGN.meta,
      pageTitle: 'Chiến dịch',
      breadcrumb: [
        {
          text: 'Chiến dịch',
          to: { name: constRouter.CAMPAIGN.name },
        },
        {
          text: 'Sửa chiến dịch',
          active: true,
        },
      ],
    },
  },
  {
    path: constRouter.CAMPAIGN_VIEW.path,
    name: constRouter.CAMPAIGN_VIEW.name,
    component: () => import('@/views/campaign/campaign-view/CampaignView.vue'),
    meta: {
      ...constRouter.CAMPAIGN.meta,
      pageTitle: 'Chiến dịch',
      breadcrumb: [
        {
          text: 'Chiến dịch',
          to: { name: constRouter.CAMPAIGN.name },
        },
        {
          text: 'Xem chiến dịch',
          active: true,
        },
      ],
    },
  },
];
